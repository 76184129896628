































import { Component, Vue } from "vue-property-decorator";
import GA from "@/app/modules/_global/classes/Analytics";

type Messages = {
  success?: null | string;
  errors: null | object;
};

@Component({
  components: {
    Alert: () => import("@/app/modules/_global/components/Alert.vue"),
    Form_: () => import("@/app/modules/profile/components/ChangePasswordForm.vue")
  }
})
export default class ChangePasswordPage extends Vue {
  constructor() {
    super();

    GA.addCustomDimension(1, `${this.userData?.user.id}`);
    GA.trackView("Change password");
  }

  passwords: object = {};

  messages: Messages = {
    success: null,
    errors: null
  };

  get appData() {
    return this.$store.getters["app/data"];
  }

  get appLayout() {
    try {
      return this.appData.layout;
    } catch (e) {
      return null;
    }
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  updatePassword() {
    this.$store
      .dispatch("profile/updatePassword", this.passwords)
      .then(({ data }) => {
        this.scrollTop();

        this.passwords = {};
        this.messages = {
          success: data.message,
          errors: null
        };
      })
      .catch(error => {
        this.messages = {
          success: null,
          errors: error.response.data.errors
        };
      });
  }

  scrollTop() {
    const card = document.getElementById("card");
    if (card) card.scrollIntoView({ behavior: "smooth" });
  }
}
